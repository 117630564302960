import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Testimonials from "../components/testimonials"
import ContactBar from "../components/contactbar"

const IndexPage = ({ data: { wpgraphql } }) => {
  return (
    <Layout>
      <div className="container">
        <h1>Services</h1>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wpgraphql {
      posts {
        edges {
          node {
            author {
              id
              name
            }
            date
            title
            slug
            content
            id
          }
        }
      }

      testimonials {
        edges {
          node {
            title
            content
            id
          }
        }
      }
    }
  }
`
